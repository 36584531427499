import {CountryCode, parsePhoneNumberWithError} from "libphonenumber-js";
import type {Dictionary} from "lodash";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import set from "lodash/set";
import uniqBy from "lodash/uniqBy";
import {Answers, ContactAnswer, HydratedContactAnswer} from "../api/answers";
import type {Role} from "../api/role";
import {ListUser, ListUserLookUpTable} from "../api/user";
import {Internal, QuestionType, RoleType} from "../enums";
import {processTreeTopDown} from "../profile";
import {JSONObject, JSONQuestion} from "../utils";

export const FALLBACK_PHONE_COUNTRY_CODE = "US";

export interface PhoneInfo {
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  carrierCode?: string;
  ext?: string;
  country?: string;
}

export function isContactCenterRole(role: Role, contactRoleKeys: string[]): boolean {
  return role.type === RoleType.TOPIC_OWNER || contactRoleKeys.includes(role.key);
}

export interface ContactAnswerKeys {
  current: string[];
  counter: string[];
}

export interface ContactAnswers {
  current: Array<ContactAnswer | HydratedContactAnswer>;
  counter: Array<ContactAnswer | HydratedContactAnswer>;
}

export interface ContactUserIds {
  current: string[];
  counter: string[];
}

export interface ContactUsers {
  current: ListUser[] | ListUserLookUpTable;
  counter: ListUser[] | ListUserLookUpTable;
}

export function getContactAnswerKeys(questionTree: JSONQuestion): ContactAnswerKeys {
  const currentContactKeyMap: Dictionary<boolean> = {};
  const counterContactKeyMap: Dictionary<boolean> = {};
  processTreeTopDown((question: JSONQuestion) => {
    if (question.type === QuestionType.CONTACT) {
      if (question.internalUse) {
        counterContactKeyMap[question.key] = true;
      } else {
        currentContactKeyMap[question.key] = true;
      }
    }
  })(questionTree);

  return {
    current: Object.keys(currentContactKeyMap),
    counter: Object.keys(counterContactKeyMap),
  };
}

export function getPublicContactAnswers(
  answers: Answers | JSONObject,
  publicContactKeys: string[],
): Array<ContactAnswer | HydratedContactAnswer> {
  return uniqBy(
    publicContactKeys.flatMap<ContactAnswer | HydratedContactAnswer>((key) => answers[key] || []),
    (a) => a.userId,
  );
}

export function getContactAnswers(
  questionTree: JSONQuestion,
  answers: Answers | JSONObject,
  specificContactKeys?: ContactAnswerKeys,
): ContactAnswers {
  const contactKeys = specificContactKeys || getContactAnswerKeys(questionTree);

  return {
    counter: uniqBy(
      contactKeys.counter.flatMap<ContactAnswer | HydratedContactAnswer>((key) => get(answers, key) || []),
      (a) => a?.userId,
    ),
    current: uniqBy(
      contactKeys.current.flatMap<ContactAnswer | HydratedContactAnswer>((key) => get(answers, key) || []),
      (a) => a?.userId,
    ),
  };
}

export function getContactUserIds(
  questionTree: JSONQuestion,
  answers: Answers | JSONObject,
  specificContactKeys?: ContactAnswerKeys,
): ContactUserIds {
  const contactKeys = specificContactKeys || getContactAnswerKeys(questionTree);

  const contactAnswers = getContactAnswers(questionTree, answers, contactKeys);

  return {
    counter: contactAnswers.counter.map<string>((answer) => answer.userId),
    current: contactAnswers.current.map<string>((answer) => answer.userId),
  };
}

export function hydrateContactUsers(
  questionTree: JSONQuestion,
  answers: Answers | JSONObject,
  users: ContactUsers,
  specificContactKeys?: ContactAnswerKeys,
) {
  const contactKeys = specificContactKeys || getContactAnswerKeys(questionTree);

  const usersById: Record<keyof ContactUsers, ListUserLookUpTable> = {
    current: Array.isArray(users.current) ? keyBy(users.current, (u) => u._id) : users.current,
    counter: Array.isArray(users.counter) ? keyBy(users.counter, (u) => u._id) : users.counter,
  };

  const ternals: Array<keyof (ContactAnswerKeys | ContactUsers)> = ["counter", "current"];

  ternals.forEach((ternal) => {
    contactKeys[ternal].forEach((contactProp) => {
      const contactAnswer = get(answers, contactProp);
      if (contactAnswer) {
        set(
          answers,
          contactProp,
          Array.isArray(contactAnswer)
            ? contactAnswer.map<HydratedContactAnswer>((ans: ContactAnswer) => ({
                ...ans,
                [Internal.HYDRATED_ANSWER_KEY]: usersById[ternal][ans?.userId],
              }))
            : {
                ...contactAnswer,
                [Internal.HYDRATED_ANSWER_KEY]: usersById[ternal][contactAnswer?.userId],
              },
        );
      }
    });
  });
}

export function parsePhoneNumberOrThrow(
  val: string | number | null | undefined,
  defaultCountryCode: string = FALLBACK_PHONE_COUNTRY_CODE,
): PhoneInfo | undefined {
  // We won't consider an empty value as an error.
  if (val === undefined || val === null || val === "") {
    return undefined;
  }
  if (typeof val === "number") {
    val = String(val);
  }
  const parsed = parsePhoneNumberWithError(val, defaultCountryCode as CountryCode);
  return {
    countryCallingCode: parsed.countryCallingCode,
    nationalNumber: parsed.nationalNumber,
    number: parsed.number,
    ...(parsed.carrierCode && {carrierCode: parsed.carrierCode}),
    ...(parsed.ext && {ext: parsed.ext}),
    ...(parsed.country && {country: parsed.country}),
  };
}

export function safeParsePhoneNumber(
  val: string | number | null | undefined,
  defaultCountryCode: string = FALLBACK_PHONE_COUNTRY_CODE,
): PhoneInfo | undefined {
  try {
    return parsePhoneNumberOrThrow(val, defaultCountryCode);
  } catch (e) {
    return undefined;
  }
}

export function looksLikeContactAnswer(answer: any): boolean {
  if (!answer) {
    return false;
  }
  return !!answer.userId || (Array.isArray(answer) && !!answer[0]?.userId);
}
