<template>
  <Dialog
    :id="id"
    :modal="!hideBackdrop"
    :baseZIndex="baseZIndex"
    @hide="$emit('hide')"
    @afterHide="$emit('hidden')"
    @show="
      $emit('show');
      $emit('shown');
    "
    :header="title"
    :closable="!hideHeaderClose && !$slots['modal-header']"
    :closeOnEscape="!noCloseOnEsc"
    :showHeader="!hideHeader"
    :dismissableMask="!noCloseOnBackdrop"
    :contentClass="contentClass"
    :aria-label="title || translate('prime.aria.dialog')"
    v-model:visible="isVisible"
    :pt="{
      root: {
        class: modalClass + ' ' + dialogClass,
      },
      header: {
        class: 'modal-header ' + headerClass,
      },
      content: {
        class: 'modal-body',
      },
      footer: {
        class: footerClass,
      },
    }"
    :style="dialogStyle"
    :breakpoints="dialogBreakPoints"
  >
    <template #closeicon>
      <slot name="modal-header-close" />
    </template>

    <template #header v-if="!hideHeader">
      <slot name="modal-header">
        <slot name="modal-title" />
      </slot>
    </template>

    <template #default>
      <slot name="default" />
    </template>

    <template #footer v-if="!hideFooter">
      <slot name="modal-footer">
        <div class="default-footer">
          <Button
            :size="buttonSize"
            :severity="cancelVariant || 'secondary'"
            v-if="!okOnly"
            :label="cancelTitle || translate('actions.cancel')"
            @click="cancel()"
          />
          <Button
            :size="buttonSize"
            :severity="okVariant || 'primary'"
            :disabled="okDisabled"
            :label="okTitle || translate('actions.ok')"
            @click="ok($event)"
          />
        </div>
      </slot>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import {modalEmitter, ModalEvent} from "@/components/modal/GraphiteModalDirective";
import {translate} from "@/composables/i18n";
import factory from "@/logging";
import isUndefined from "lodash/isUndefined";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import type {ComputedRef, VNode} from "vue";
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from "vue";

const logger = factory.getLogger("GraphiteModal");

const props = defineProps<{
  // autoFocusButton?: string; // default: null
  // bodyBgVariant?: string;
  baseZIndex?: number;
  bodyClass?: string | string[] | Record<string, boolean>;
  // bodyTextVariant?: string;
  busy?: boolean; // default: false
  buttonSize?: string;
  // cancelDisabled?: boolean; // default: false
  cancelTitle?: string; // default: 'Cancel'
  // cancelTitleHtml?: string;
  cancelVariant?: string; // default: 'secondary'
  centered?: boolean; // default: false
  contentClass?: string | string[] | Record<string, boolean>;
  dialogClass?: string | string[] | Record<string, boolean>;
  // footerBgVariant?: string;
  // footerBorderVariant?: string;
  footerClass?: string | string[] | Record<string, boolean>;
  // footerTag?: string; // default: 'footer'
  // footerTextVariant?: string;
  // headerBgVariant?: string;
  // headerBorderVariant?: string;
  headerClass?: string | string[] | Record<string, boolean>;
  // headerCloseContent?: string; // default: '&times;'
  // headerCloseLabel?: string; // default: 'Close'
  // headerCloseVariant?: string;
  // headerTag?: string; // default: 'header'
  // headerTextVariant?: string;
  // TODO?: Rename to `noBackdrop` and deprecate `hideBackdrop`
  hideBackdrop?: boolean; // default: false
  // TODO?: Rename to `noFooter` and deprecate `hideFooter`
  hideFooter?: boolean; // default: false
  // TODO?: Rename to `noHeader` and deprecate `hideHeader`
  hideHeader?: boolean; // default: false
  // TODO?: Rename to `noHeaderClose` and deprecate `hideHeaderClose`
  hideHeaderClose?: boolean; // default: false
  // ignoreEnforceFocusSelector?: string | string[];
  // lazy?: boolean; // default: false
  modalClass?: string | string[] | Record<string, boolean>;
  noCloseOnBackdrop?: boolean; // default: false
  noCloseOnEsc?: boolean; // default: false
  // noEnforceFocus?: boolean; // default: false
  // noFade?: boolean; // default: false
  // noStacking?: boolean; // default: false
  okDisabled?: boolean; // default: false
  okOnly?: boolean; // default: false
  okTitle?: string; // default: 'OK'
  // okTitleHtml?: string;
  okVariant?: string; // default: 'primary'
  // HTML Element, CSS selector string or Vue component instance
  // returnFocus?: [HTMLElement, Object, string];
  scrollable?: boolean; // default: false
  size?: string; // default: 'md'
  static?: boolean; // default: false
  title?: string;
  titleClass?: string | string[] | Record<string, boolean>;
  // titleHtml?: string;
  // titleSrOnly?: boolean; // default: false
  // titleTag?: string; // default: 'h5'
  visible?: boolean; // v-model:visible

  /** @deprecated use visible? */
  modelValue?: boolean;
  /** @deprecated use visible? */
  value?: boolean;

  id?: string;
}>();
const emit = defineEmits<{
  cancel: [];
  change: [val: boolean];
  close: [];
  hidden: [];
  hide: [];
  ok: [MouseEvent];
  show: [];
  shown: [];
  "update:visible": [val: boolean];
  "update:modelValue": [val: boolean];
  input: [val: boolean];
}>();

defineSlots<{
  "modal-backdrop"(): VNode[];
  "modal-cancel"(): VNode[];
  "modal-footer"(): VNode[];
  "modal-header"(): VNode[];
  "modal-header-close"(): VNode[];
  "modal-ok"(): VNode[];
  "modal-title"(): VNode[];
}>();

const {size} = toRefs(props);

const unimplementedProps = ["bodyClass", "busy", "scrollable", "static"];

const dialogStyle = computed(() => {
  if (size.value === "xl") {
    return {width: "1140px"};
  } else if (size.value === "md") {
    return {width: "700px"};
  } else if (size.value === "lg") {
    return {width: "800px"};
  } else if (size.value === "sm") {
    return {width: "300px"};
  } else if (size.value === "none") {
    return {};
  }
  return {width: "500px"};
});

const dialogBreakPoints = computed(() => {
  if (size.value === "sm" || size.value === "md" || isUndefined(size.value)) {
    return undefined;
  }
  return {"992px": "800px", "576px": "500px"};
});

const _visible = ref(props.visible ?? true);
watch(
  () => props.visible,
  (v) => {
    _visible.value = v;
  },
);

const isVisible = computed({
  get(): boolean {
    return _visible.value;
  },
  set(v: boolean) {
    _visible.value = v;
    emit("update:visible", v);
    emit("update:modelValue", v);
    emit("input", v);
    emit("change", v);
  },
});

function cancel() {
  close();
  emit("cancel");
}

function ok(event: MouseEvent) {
  emit("ok", event);
  if (!event.defaultPrevented) {
    close();
  }
}

const buttonSize = computed<"small" | "large" | undefined>(() => {
  if (props.buttonSize === "lg") {
    return "large";
  }

  if (props.buttonSize === "sm") {
    return "small";
  }

  return props.buttonSize as "small" | "large" | undefined;
});

onMounted(() => {
  modalEmitter.on(ModalEvent.Open, listenForTriggers);
});

onUnmounted(() => {
  modalEmitter.off(ModalEvent.Open, listenForTriggers);
});

function listenForTriggers(id: string) {
  if (id !== props.id) {
    return;
  }

  open();
}

function open() {
  isVisible.value = true;
}

function close() {
  isVisible.value = false;
}

function toggle() {
  isVisible.value = !isVisible.value;
}

defineExpose<GraphiteModalExposedMethods>({
  open,
  close,
  toggle,
});

defineOptions({
  name: "GraphiteModal",
});
</script>

<script lang="ts">
export interface GraphiteModalExposedMethods {
  open: () => void;
  close: () => void;
  toggle: () => void;
}
</script>
