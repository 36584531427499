export enum EventEntity {
  SELF = "self",
  COUNTERPARTY = "counterparty",
}

export enum ActionType {
  MESSAGE = "message",
  EMAIL = "email",
  NOTIFICATION = "notification",
  WEBHOOK = "webhook",
  TASK = "task",
  TASK_COMPLETION = "task_completion",
  APPROVAL = "approval",
  DELETE_APPROVAL = "delete_approval",
  SUBMIT = "submit",
  EDIT = "edit",
  TOPIC_REVIEW = "topic_review",
  PAYPAL = "paypal",
  LINK = "link",
  PROFILE_PDF = "profile pdf",
  DOW_JONES_CHECK = "dow_jones_check",
  RBA = "rba",
  SECZETTA = "seczetta",
  ZENDESK = "zendesk",
  DISCONNECT = "disconnect",
  CREATE_CONNECTION = "create_connection",
  START_WORKFLOW = "start_workflow",
  STOP_WORKFLOW = "stop_workflow",
  RESUME_WORKFLOW = "resume_workflow",
  ABORT_WORKFLOW = "abort_workflow",
  EDIT_USER = "edit_user",

  NONE = "none",
}

export enum ClientTriggerEventType {
  ANSWER_VALUE_ADDED = "ANSWER_VALUE_ADDED",
  ANSWER_VALUE_REMOVED = "ANSWER_VALUE_REMOVED",
  ANSWER_UPDATED = "ANSWER_UPDATED",
  CONNECTION_STARTED = "CONNECTION_STARTED",
}

export enum ClientTriggerActionType {
  ADD_INSTANCE = "ADD_INSTANCE",
  REMOVE_INSTANCE = "REMOVE_INSTANCE",
  EDIT = "EDIT",
}
