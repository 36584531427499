import {sortBy} from "lodash";
import {always, indexBy, isEmpty, mergeDeepLeft, path, prop} from "ramda";
import {GroupAnswer, isGroupAnswer} from "../answers";
import {isFreeTextAnswer} from "../answers/values";
import type {Answers, ConnectionDataLite} from "../api/connection";
import {ListOption} from "../api/lists";
import {QuestionType} from "../enums";
import {FreeTextAnswerKeys, Internal, MagicAnswerKeys} from "../enums/answers";
import {SpecialKeyValues} from "../enums/question";
import {filter, find, findByKey, isMultipleGroup} from "../profile";
import {isDefaultInstance} from "../profile/group-util";
import {counterpartyBaseKey, isCounterpartyKey} from "../props";
import {Expression, ExpressionEvaluator} from "../rules/expression";
import {coerceToArray, ensureArray, JSONObject, JSONQuestion, JSONValue} from "../utils";
import {INSTANCE_ORDER} from "../utils/constants";
import {isLocaleObj} from "../utils/locale";
import factory from "../utils/logging";

export enum GetOptionsMethod {
  USE_OPTIONS_PROPERTY = 1,
  GET_OPTIONS_FROM_SERVER, // Might need to use XHR
  GET_OPTIONS_FROM_GROUP_INSTANCES,
  GET_OPTIONS_FROM_ANSWERS,
}

export interface ReturnedOptionsProcessingMethod {
  getOptionsMethod: GetOptionsMethod;
  filterOptions: boolean;
}

export interface BasicOption extends JSONObject {
  value: string | boolean | number;
  label?: string;
}

export interface QuestionOption extends BasicOption {
  html?: string; // either label or html required
  shape?: string;
  disabled?: boolean;
  deprecated?: boolean;
  helpText?: string;
  hint?: string;
  helpTextMarkdown?: string;
  conflictGroup?: string;
  group?: string;
  groupOptions?: QuestionOption[];
}

// idk if `HierarchyOption` is a valid name
export interface HierarchyOption {
  column: string;
  parent?: string;
}

const logger = factory.getLogger("Options");

export function filterOptions(
  options: QuestionOption[],
  optionsFilter: Expression | undefined,
  answers?: JSONObject | JSONObject[],
): QuestionOption[] {
  const answersArray = coerceToArray(answers);
  if (optionsFilter === null || optionsFilter === undefined) {
    return options;
  }

  if (optionsFilter && answers) {
    const evaluator = new ExpressionEvaluator(optionsFilter);
    return options.map((option) => ({
      ...option,
      deprecated: option.deprecated || !evaluator.evaluate({[MagicAnswerKeys.VALUE]: option.value}, ...answersArray),
    }));
  }
  return [];
}

export function sortOptions<Q extends QuestionOption | ListOption>(options: Q[], listKey: string): Q[] {
  switch (listKey) {
    case SpecialKeyValues.COUNTRIES:
    case SpecialKeyValues.COUNTRIES_GLOBAL:
      return sortBy(options, (o) => o.label);
  }
  return options;
}

export function mergeOptions(options: QuestionOption[], options2: QuestionOption[]) {
  return options.map((o) => {
    return mergeDeepLeft(o, options2.find((v) => o.value === v.value) || {});
  });
}

export function getOptionsData(optionsAnswerKey?: string, connectionData?: ConnectionDataLite) {
  let answerKey = optionsAnswerKey;
  let answers: Answers | undefined = connectionData?.answers;
  let questions: JSONQuestion | undefined = connectionData?.questions;

  if (optionsAnswerKey && isCounterpartyKey(optionsAnswerKey) && connectionData?.counter) {
    answerKey = counterpartyBaseKey(optionsAnswerKey);
    answers = connectionData?.counter?.answers;
    questions = connectionData?.counter?.questions;
  }

  return {answers, questions, answerKey};
}

/**
 * There are several ways that the options need to be derived based on question data. This can effect how we have to
 * most efficiently get the options for a given question. For example, if a question has optionsAnswerKey set then we
 * have to refresh the options when questions and answers change, but if options are included with the options property
 * then we do not.
 */
export function getOptionsProcessingMethod(elementData: JSONQuestion, answers?: JSONObject | JSONObject[]) {
  const processingReturnData: ReturnedOptionsProcessingMethod = {
    getOptionsMethod: GetOptionsMethod.USE_OPTIONS_PROPERTY,
    filterOptions: false,
  };
  if (elementData?.list?.key) {
    processingReturnData.getOptionsMethod = GetOptionsMethod.GET_OPTIONS_FROM_SERVER;
  } else if (elementData.optionsAnswerKey) {
    processingReturnData.getOptionsMethod = GetOptionsMethod.GET_OPTIONS_FROM_ANSWERS;
    const answersArray = coerceToArray(answers);
    const answerKeyParts = elementData.optionsAnswerKey.split(".");
    outerLoop: for (let groupPathLength = answerKeyParts.length; groupPathLength > 0; groupPathLength--) {
      for (const a of answersArray) {
        const values = path(answerKeyParts.slice(0, groupPathLength), a);
        if (values) {
          if (isGroupAnswer(values)) {
            processingReturnData.getOptionsMethod = GetOptionsMethod.GET_OPTIONS_FROM_GROUP_INSTANCES;
          }
          break outerLoop;
        }
      }
    }
  } else if (
    (elementData.type === QuestionType.USER_SELECT_EMAIL ||
      elementData.type === QuestionType.USER_SELECT ||
      elementData.type === QuestionType.CONTACT) &&
    !elementData.options &&
    !elementData.optionsFilter
  ) {
    processingReturnData.getOptionsMethod = GetOptionsMethod.GET_OPTIONS_FROM_SERVER;
  }

  return processingReturnData;
}

/**
 *
 *
 * @param optionsAnswerKey
 * @param optionsFilter
 * @param answers
 * @param questions
 * @param userLocale
 * @param groupTemplateAnswerKey
 */
function getOptionsFromGroupInstances(
  optionsAnswerKey: string,
  {optionsFilter}: JSONQuestion,
  answers: JSONObject | JSONObject[],
  questions: JSONQuestion,
  userLocale?: string,
  groupTemplateAnswerKey?: string,
): QuestionOption[] {
  const answerKeyParts = optionsAnswerKey.split(".");
  const arrayAnswers = coerceToArray(answers);
  let values: any;
  let pathRemainder: string[] = [];
  for (let groupPathLength = answerKeyParts.length; groupPathLength > 0; groupPathLength--) {
    for (const a of arrayAnswers) {
      values = path(answerKeyParts.slice(0, groupPathLength), a);
      if (values) {
        break;
      }
    }
    if (isGroupAnswer(values)) {
      pathRemainder = answerKeyParts.slice(groupPathLength);
      optionsAnswerKey = answerKeyParts.slice(0, groupPathLength).join(".");
      break;
    }
  }
  if (!values) {
    return [];
  }

  let applyFilter: (instanceId: string) => boolean = always(true);
  if (optionsFilter) {
    const evaluator = new ExpressionEvaluator(optionsFilter);
    applyFilter = (instanceId: string) => {
      return Boolean(
        evaluator.evaluate(...arrayAnswers, values[instanceId] as JSONObject, {[MagicAnswerKeys.VALUE]: instanceId}),
      );
    };
  }
  if (pathRemainder.length > 0) {
    return (values[INSTANCE_ORDER] || []).map((instanceId) => {
      return {
        label: path(pathRemainder, values[instanceId]),
        value: instanceId,
        deprecated: path([Internal.DELETED], values[instanceId]) || !applyFilter(instanceId),
      };
    });
  }

  groupTemplateAnswerKey = groupTemplateAnswerKey || optionsAnswerKey;
  const group: JSONQuestion = find(
    (q: JSONQuestion) => isMultipleGroup(q) && q.key === groupTemplateAnswerKey,
    questions,
  );
  if (!group) {
    return [];
  }
  const groupInstance = group.children && group.children[0];
  const hasOnlyDefaultInstance = isDefaultInstance(values as GroupAnswer)((values[INSTANCE_ORDER] || [])[0]);
  if (!group || !groupInstance || hasOnlyDefaultInstance) {
    return [];
  }
  let uniqueQuestions = filter(prop("groupKey"), groupInstance).filter((q) => q.visible);
  if (uniqueQuestions.length === 0) {
    uniqueQuestions = filter(prop("groupKey"), groupInstance);
  }
  const uniqueKey = uniqueQuestions.map(prop("key")).map((k: string) => k.split(".")[2]);
  const getUniqueKey = (instance: JSONObject) => {
    return uniqueKey
      .map((k) => {
        const currentValue = instance[k];
        const currentLocale =
          userLocale || (isFreeTextAnswer(currentValue) && currentValue?.[FreeTextAnswerKeys.ANSWER_LOCALE]);
        if (isLocaleObj(currentValue)) {
          return path([currentLocale, "value"], currentValue);
        } else {
          return currentValue;
        }
      })
      .join(", ");
  };
  return values[INSTANCE_ORDER].map((instanceId) => {
    const label = getUniqueKey(values[instanceId] as JSONObject);
    return {
      label,
      value: instanceId,
      deprecated: path([Internal.DELETED], values[instanceId]) || !applyFilter(instanceId),
    };
  });
}

/**
 * Handles most cases of getting options and can be used in computed methods. The only case it cannot handle needing to
 * get the options from the backend.
 *
 * @param elementData
 * @param optionsProcessingMethod
 * @param answers
 * @param questions
 * @param answerKey
 * @param userLocale
 */
export function getOptionsWithoutServerCall(
  elementData: JSONQuestion,
  optionsProcessingMethod: ReturnedOptionsProcessingMethod,
  answers: JSONObject | JSONObject[],
  questions,
  answerKey,
  userLocale?: string,
): QuestionOption[] {
  if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.USE_OPTIONS_PROPERTY) {
    return filterOptions(elementData.options || [], elementData.optionsFilter, answers);
  } else if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_GROUP_INSTANCES) {
    return getOptionsFromGroupInstances(
      answerKey,
      elementData,
      answers,
      questions,
      userLocale,
      elementData.groupTemplateAnswerKey,
    );
  } else if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_ANSWERS) {
    return getOptionsFromAnswers(answerKey, elementData, answers, questions, elementData.groupTemplateAnswerKey);
  } else {
    return [];
  }
}

/**
 * Get options from answers (option
 *
 * @param optionsAnswerKey
 * @param optionsFilter
 * @param answers
 * @param questions
 * @param groupTemplateAnswerKey
 */
function getOptionsFromAnswers(
  optionsAnswerKey,
  {optionsFilter}: JSONQuestion,
  answers: JSONObject | JSONObject[],
  questions: JSONQuestion,
  groupTemplateAnswerKey?: string,
) {
  const answerKeyParts = optionsAnswerKey.split(".");
  const arrayAnswers = coerceToArray(answers);
  let values: JSONValue;
  for (const a of arrayAnswers) {
    values = path(answerKeyParts, a);
    if (values) {
      break;
    }
  }
  // GET OPTIONS FROM ANOTHER QUESTION
  const arrayValues = values ? ensureArray(values).map(String) : [];
  if (!questions) {
    return [];
  }
  const question = findByKey(optionsAnswerKey, questions);
  if (question) {
    const optionsProcessingMethod = getOptionsProcessingMethod(question, answers);
    let optionsQuestionOptions;
    if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.USE_OPTIONS_PROPERTY) {
      optionsQuestionOptions = filterOptions(question.options || [], question.optionsFilter, answers);
    } else if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_GROUP_INSTANCES) {
      optionsQuestionOptions = getOptionsFromGroupInstances(
        optionsAnswerKey,
        question,
        answers,
        questions,
        undefined,
        groupTemplateAnswerKey,
      );
    } else if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_ANSWERS) {
      logger.error(
        () =>
          `Error, circular logic detected in options set up for question: ${question.key} with options answer key ${optionsAnswerKey}`,
      );
    }
    if (optionsQuestionOptions && !isEmpty(optionsQuestionOptions)) {
      const byValue = indexBy((o: any) => o.value, optionsQuestionOptions);
      return filterOptions(
        arrayValues.map((v) => ({
          value: v,
          label: byValue[v] ? byValue[v].label : v,
        })),
        optionsFilter,
        answers,
      );
    }
  }
  logger.trace(
    () => `about to return getOptionsWorker ${optionsAnswerKey}, ${arrayValues}, ${optionsFilter}, ${answers}`,
  );
  return filterOptions(
    arrayValues.map((v) => ({label: v, value: v})),
    optionsFilter,
    answers,
  );
}
