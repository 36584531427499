import cloneDeep from "lodash/cloneDeep";
import {autoMutations} from "../../utils";
import globalLogger from "../../../logging";

const logger = globalLogger.getLogger("admin.users");

const state = {
  entity: null,
  originalEntity: null,
  error: null,
  editingSso: false,
  editingFlags: false,
  roles: [],
};

const actions = {
  async getEntity({commit, rootState}) {
    try {
      const user = rootState.user;
      const response = await this.httpGet(`/api/admin/entities/${user.activeEntityId}`);
      const {entity} = response.data;
      if (!entity.ssoSettings) {
        entity.ssoSettings = {};
      }
      if (!entity.ssoSettings.domains) {
        entity.ssoSettings.domains = [];
      }
      if (!entity.ssoSettings.roleMapping) {
        entity.ssoSettings.roleMapping = [];
      }
      if (!entity.ssoSettings.ssoAttributeMapping) {
        entity.ssoSettings.ssoAttributeMapping = [];
      }
      commit("entity", entity);
      commit("originalEntity", cloneDeep(entity));
    } catch (e) {
      logger.error(e);
      commit("error", e.response ? e.response.data : e);
    }
  },

  async getRoles({commit}) {
    try {
      const response = await this.httpGet(`/api/users/roles`);
      const {roles} = response.data;
      commit("roles", roles);
    } catch (e) {
      logger.error(e);
      commit("error", e.response ? e.response.data : e);
    }
  },

  resetEntity({commit, state}) {
    commit("entity", cloneDeep(state.originalEntity));
    commit("editingSso", false);
    commit("editingFlags", false);
  },

  async updateSsoSettings({commit, rootState, state, dispatch}) {
    try {
      const user = rootState.user;
      const ssoSettings = state.entity.ssoSettings;
      const orginalSsoSettings = state.originalEntity.ssoSettings;
      await this.httpPut(`/api/admin/entities/${user.activeEntityId}/sso`, {ssoSettings, orginalSsoSettings});
      commit("editingSso", false);
      dispatch("getEntity");
    } catch (e) {
      logger.error(e);
      commit("error", e.response ? e.response.data : e);
    }
  },

  async updateFlags({commit, rootState, state}) {
    try {
      const user = rootState.user;
      const flags = state.entity;
      await this.httpPut(`/api/admin/entities/${user.activeEntityId}/flags`, flags);
      commit("editingFlags", false);
    } catch (e) {
      logger.error(e);
      commit("error", e.response ? e.response.data : e);
    }
  },

  addProvisionFilter({state, commit}) {
    const entity = state.entity;
    if (!entity.ssoSettings.autoProvisionFilters) {
      entity.ssoSettings.autoProvisionFilters = [];
    }
    entity.ssoSettings.autoProvisionFilters.push({});
    commit("entity", entity);
  },

  removeProvisionFilter({state, commit}, filter) {
    const filters = state.entity.ssoSettings.autoProvisionFilters;
    const ix = filters.indexOf(filter);
    if (ix > -1) {
      filters.splice(ix, 1);
      state.entity.ssoSettings.autoProvisionFilters = filters;
      commit("entity", state.entity);
    }
  },

  addRoleMapping({state, commit}) {
    const entity = state.entity;
    entity.ssoSettings.roleMapping.push({});
    commit("entity", entity);
  },

  removeRoleMapping({state, commit}, filter) {
    const filters = state.entity.ssoSettings.roleMapping;
    const ix = filters.indexOf(filter);
    if (ix > -1) {
      filters.splice(ix, 1);
      state.entity.ssoSettings.roleMapping = filters;
      commit("entity", state.entity);
    }
  },

  addUserAttributeMapping({state, commit}) {
    const entity = state.entity;
    entity.ssoSettings.ssoAttributeMapping.push({
      exclusionList: [],
      listValues: [],
      manualUpdatePermissions: [],
      manualUpdateEnabled: false,
    });
    commit("entity", entity);
  },

  removeUserAttributeMapping({state, commit}, filter) {
    const filters = state.entity.ssoSettings.ssoAttributeMapping;
    const ix = filters.indexOf(filter);
    if (ix > -1) {
      filters.splice(ix, 1);
      state.entity.ssoSettings.ssoAttributeMapping = filters;
      commit("entity", state.entity);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state: cloneDeep(state),
  actions,
  getters,
  mutations: autoMutations(state),
};
