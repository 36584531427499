import {deburr} from "lodash";
import isString from "lodash/isString";

// stop words provided by https://gist.github.com/sebleier/554280
export const stopwords = [
  "i",
  "me",
  "my",
  "myself",
  "we",
  "our",
  "ours",
  "ourselves",
  "you",
  "your",
  "yours",
  "yourself",
  "yourselves",
  "he",
  "him",
  "his",
  "himself",
  "she",
  "her",
  "hers",
  "herself",
  "it",
  "its",
  "itself",
  "they",
  "them",
  "their",
  "theirs",
  "themselves",
  "what",
  "which",
  "who",
  "whom",
  "this",
  "that",
  "these",
  "those",
  "am",
  "is",
  "are",
  "was",
  "were",
  "be",
  "been",
  "being",
  "have",
  "has",
  "had",
  "having",
  "do",
  "does",
  "did",
  "doing",
  "a",
  "an",
  "the",
  "and",
  "but",
  "if",
  "or",
  "because",
  "as",
  "until",
  "while",
  "of",
  "at",
  "by",
  "for",
  "with",
  "about",
  "against",
  "between",
  "into",
  "through",
  "during",
  "before",
  "after",
  "above",
  "below",
  "to",
  "from",
  "up",
  "down",
  "in",
  "out",
  "on",
  "off",
  "over",
  "under",
  "again",
  "further",
  "then",
  "once",
  "here",
  "there",
  "when",
  "where",
  "why",
  "how",
  "all",
  "any",
  "both",
  "each",
  "few",
  "more",
  "most",
  "other",
  "some",
  "such",
  "no",
  "nor",
  "not",
  "only",
  "own",
  "same",
  "so",
  "than",
  "too",
  "very",
  "s",
  "t",
  "can",
  "will",
  "just",
  "don",
  "should",
  "now",
];

// order matters, be careful when adjusting. note it will start in lowercase
const normalizeReplacements: Array<{
  regex: RegExp;
  replacement: string | ((substring: string, ...args: any[]) => string);
}> = [
  // these were taken straight from Carlos' code
  {regex: /http\S+/g, replacement: " "},
  {regex: /#+/g, replacement: " "},
  {regex: /@[A-Za-z0-9]+/g, replacement: " "},
  {regex: /([A-Za-z]+)'s/g, replacement: "$1 is"},
  {regex: /'ve/g, replacement: " have "},
  {regex: /won't/g, replacement: "will not "},
  {regex: /isn't/g, replacement: "is not "},
  {regex: /can't/g, replacement: "can not "},
  {regex: /n't/g, replacement: " not "},
  {regex: /i'm/g, replacement: "i am "},
  {regex: /'re/g, replacement: " are "},
  {regex: /'d/g, replacement: " would "},
  {regex: /'ll/g, replacement: " will "},
  {regex: /[^\w-]/g, replacement: " "},
  {regex: /\b\d+\b/g, replacement: " "},
  {regex: /\s+/g, replacement: " "},
];

const stopwordsReplacements = [
  {
    regex: new RegExp(`([\\w-]?)([\\w-]?)\\b(${stopwords.join("|")})\\b([\\w-]?)([\\w-]?)`, "g"),
    replacement: (match, one, two, _three, four, five) => {
      // TODO need a better way to handle removing stopwords, but not in hyphenated words
      if ((one.match(/(\w|-)/) && two.match(/(\w|-)/)) || (four.match(/(\w|-)/) && five.match(/(\w|-)/))) {
        return match;
      }

      return `${one}${two}${four}${five}`;
    },
  },
  {regex: /\s+/g, replacement: " "},
];

export function stripStopwords(txts: string): string;
export function stripStopwords(txts: string[]): string[];
export function stripStopwords(txts: string[] | string): string[] | string {
  return arrayOrStringHandler(txts, (texts) => {
    return texts.map((text) => {
      return stopwordsReplacements
        .reduce((txt: string, {regex, replacement}) => {
          return txt.replace(regex, replacement as string);
        }, text.toLowerCase())
        .trim();
    });
  });
}

export function normalizeStrings(txts: string): string;
export function normalizeStrings(txts: string[]): string[];
export function normalizeStrings(txts: string[] | string): string[] | string {
  return arrayOrStringHandler(txts, (texts) => {
    return texts.map((text) => {
      return normalizeReplacements
        .reduce((txt: string, {regex, replacement}) => {
          return txt.replace(regex, replacement as string);
        }, text.toLowerCase())
        .trim();
    });
  });
}

export function stripStopwordsAndNormalizeStrings(txts: string): string;
export function stripStopwordsAndNormalizeStrings(txts: string[]): string[];
export function stripStopwordsAndNormalizeStrings(txts: string[] | string): string[] | string {
  return arrayOrStringHandler(txts, (texts) => {
    return texts.map((text) => {
      return stripStopwords(normalizeStrings(text));
    });
  });
}

function arrayOrStringHandler<R = string>(input: string, callback: (strs: string[]) => R[]): R;
function arrayOrStringHandler<R = string>(input: string[], callback: (strs: string[]) => R[]): R[];
function arrayOrStringHandler<R = string>(input: string | string[], callback: (strs: string[]) => R[]): R | R[];
function arrayOrStringHandler<R = string>(input: string | string[], callback: (strs: string[]) => R[]): R | R[] {
  let singleInput = false;

  if (typeof input === "string") {
    singleInput = true;
    input = [input];
  }

  const result = callback(input);

  if (singleInput) {
    return result[0];
  }

  return result;
}

export function getInitials(str: undefined | null): undefined;
export function getInitials(str: string): string;
export function getInitials(str: string | undefined | null): string | undefined {
  if (str && isString(str)) {
    const initials = str
      .split(" ")
      .map((s) => (RegExp(/[\w]/).test(s.charAt(0)) ? s.charAt(0) : ""))
      .join("");
    return initials.substring(0, 2);
  }

  return undefined;
}

export type SortPair = [sort: string, dir: "asc" | "desc"];

export function normalizeSort(sortAndDir: string): SortPair;
export function normalizeSort(sortsAndDirs: string[]): SortPair[];
export function normalizeSort(sortsAndDirs: string[] | string): SortPair[] | SortPair {
  return arrayOrStringHandler(sortsAndDirs, (sads) => {
    return sads.map((sad) => {
      const dir: "asc" | "desc" = sad.startsWith("-") ? "desc" : "asc";
      const sort: string = sad.replace(/^-/, "");

      return [sort, dir];
    });
  });
}

export function inputToRegexStr(str: string, exactMatch = true) {
  // this will replace str inputs to be interpreted literally.
  // @see https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js (which the dev has made it impossible to use in our project)
  const regexStr = str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  if (exactMatch) {
    return `^${regexStr}$`;
  }

  return regexStr;
}

export function handleNilStr(str: "null" | null): null;
export function handleNilStr(str: "undefined" | undefined): undefined;
export function handleNilStr(str: string): string;
export function handleNilStr(str: string | undefined | null): string | null | undefined {
  if (str === "undefined") {
    return undefined;
  }

  if (str === "null") {
    return null;
  }

  return str;
}

// removes (hopefully all) diacritics, expands e.g. `ﬁ` to `fi`, sets to lowercase, and trims
export function normalizeStringForSearch(str?: string): string {
  return deburr(str || "")
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .trim()
    .toLowerCase();
}

// usage:
// trimLines`line1
//      line2`;
// outputs: "line1\nline2"
export function trimLines(strings: TemplateStringsArray, ...expressions: unknown[]): string {
  let result = strings[0];

  for (let i = 1, l = strings.length; i < l; i++) {
    result += expressions[i - 1];
    result += strings[i];
  }

  return result
    .split("\n")
    .map((line) => line.trim())
    .join("\n");
}
