import globalLogger from "@/logging";
import type {PGActionContext, PGActionTree} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import {RoutingKey} from "pg-isomorphic/queue";
import {ExportState} from "@/store/modules/export";

// export interface ExportState {}

export interface RowError {
  error: string;
  connectionId: string;
}

const initialState: {} = {};

const logger = globalLogger.getLogger("export");
type Ctx = PGActionContext<ExportState>;

const actions: PGActionTree<ExportState> = {
  reset({commit}: Ctx) {
    for (const k of Object.keys(initialState)) {
      commit(k, (initialState as any)[k]);
    }
  },
  joinRooms({state, commit}: Ctx, {jobId, handleMessages}: {jobId: string; handleMessages: (x: any) => void}) {
    this.join(RoutingKey.jobProgress({jobId: jobId!}));
    this.socketOn(RoutingKey.jobProgress({jobId: jobId!}), handleMessages);
  },
  async leaveRooms({state, dispatch}: Ctx, {jobId, handleMessages}: {jobId: string; handleMessages: (x: any) => void}) {
    this.leave(RoutingKey.jobProgress({jobId: jobId!}));
    this.socketOff(RoutingKey.jobProgress({jobId: jobId!}), handleMessages);
    await dispatch("reset");
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: autoMutations(initialState),
  actions,
};
