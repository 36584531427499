export enum TaskType {
  REQUIRED = "required",
  REVIEW = "review",
  ENTITY_ACCESS_REQUEST = "entity_access_request",
  NEW_USER_SETUP = "new_user_setup",
  ADVANCE_CONNECTION = "advance_connection",
  BUSINESS_OWNER = "business_owner",
  REMINDER = "reminder",
  ACKNOWLEDGMENT = "acknowledgment",
  APPROVAL = "approval",
  WORKFLOW = "workflow",
  USER_GENERATED = "user_generated",
  VERIFY_IDENTITY = "verify_identity",
}

export enum TaskTypeExtra {
  ACTION_PLAN = "action_plan",
  SUPPORT = "support",
}

export enum TaskActorType {
  USER = "User",
  ROLE = "Role",
}

export enum TaskSpecialActor {
  TOPIC_OWNER = "topic_owners",
  SUPPLIER = "supplier",
  CUSTOMER = "customer",
}

export enum TaskChangeType {
  UPDATE = "update",
  NEW = "new",
  COMPLETE = "complete",
  DELETE = "delete",
}

export enum TaskSortBy {
  CREATED = "created",
  UPDATED = "updated",
  STATE = "state",
  START_DATE = "startDate",
  DUE_DATE = "dueDate",
  PRIORITY = "priority",
}

export enum TableType {
  ME = "me",
  ME_ACTIVE = "me_active",
  ME_BACKLOG = "me_backlog",
  MY_ROLES = "my_roles",
  MY_ROLES_ACTIVE = "my_roles_active",
  MY_ROLES_BACKLOG = "my_roles_backlog",
  COMPANY = "company",
  ALL = "all",
  COUNTERPARTY = "counterparty",
  ACTION_PLAN = "action_plan",
}

export enum ApprovalType {
  ANYONE = "anyone",
  EVERYONE = "everyone",
}

export enum TaskState {
  NEW = "new", // obsolete
  IN_PROGRESS = "in_progress",
  PENDING = "pending",
  ON_HOLD = "on_hold",
  COMPLETE = "complete",
}

export enum TaskOnHoldReasons {
  PENDING_INTERNAL = "pending_internal",
  PENDING_SUPPLIER = "pending_supplier",
  OTHER = "other",
}

export enum TaskChangeReason {
  TOPIC_ANSWERS = "topic_answer",
  TOPIC_MESSAGE = "topic_message",
  TOPIC_APPROVAL = "topic_approval",
  ASSIGNMENT = "assignment",
  COMMENT = "comment",
  STATE = "state",
  STATE_REASON = "state_reason",
}

export enum TaskGroupBy {
  DEFAULT = "",
  TOPIC = "topic",
  STATUS = "status",
  PRIORITY = "priority.label.en-US",
}

export enum TaskFilterKeys {
  STATES = "state",
  OWNERS = "effectiveAssignedUsers._id",
  STAGES = "denormalizedConnectionStage",
  TOPICS = "topic",
  TYPE = "type",
}

export enum TaskOwnerFilters {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export enum TaskButtonAction {
  SAVE = "save",
  DELETE = "delete",
  CANCEL = "cancel",
}

export enum TaskBucket {
  AT_RISK = "at_risk", // SLA -1 or 0 days
  OVERDUE = "overdue", // SLA +1 day
  OVER_THIRTY_DAYS = "over_thirty_days", // SLA +30 days
  UPDATED = "updated", // Has updates logged user hasn't viewed (red dot),
  ON_HOLD = "on_hold", // duplicate of TaskState.ON_HOLD
  OPEN = "open", // combined (1) tasks with SLA, not yet at risk and (2) in progress (when no SLA)
}
